exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-cancel-tsx": () => import("./../../../src/pages/cancel.tsx" /* webpackChunkName: "component---src-pages-cancel-tsx" */),
  "component---src-pages-cheatsheets-tsx": () => import("./../../../src/pages/cheatsheets.tsx" /* webpackChunkName: "component---src-pages-cheatsheets-tsx" */),
  "component---src-pages-complain-tsx": () => import("./../../../src/pages/complain.tsx" /* webpackChunkName: "component---src-pages-complain-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-courses-tsx": () => import("./../../../src/pages/courses.tsx" /* webpackChunkName: "component---src-pages-courses-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-learnication-tsx": () => import("./../../../src/pages/learnication.tsx" /* webpackChunkName: "component---src-pages-learnication-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-socials-tsx": () => import("./../../../src/pages/socials.tsx" /* webpackChunkName: "component---src-pages-socials-tsx" */),
  "component---src-pages-speaking-tsx": () => import("./../../../src/pages/speaking.tsx" /* webpackChunkName: "component---src-pages-speaking-tsx" */),
  "component---src-pages-xa-prepare-tsx": () => import("./../../../src/pages/xa-prepare.tsx" /* webpackChunkName: "component---src-pages-xa-prepare-tsx" */),
  "component---src-templates-cheatsheet-tsx": () => import("./../../../src/templates/cheatsheet.tsx" /* webpackChunkName: "component---src-templates-cheatsheet-tsx" */),
  "component---src-templates-course-images-tsx": () => import("./../../../src/templates/course-images.tsx" /* webpackChunkName: "component---src-templates-course-images-tsx" */),
  "component---src-templates-course-tsx": () => import("./../../../src/templates/course.tsx" /* webpackChunkName: "component---src-templates-course-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

